const browser = {};

/** calculate the correct cookie host domain**/
const getCookieDomain = () => {
  const domain = document.domain;

  const firstYocoPos = domain.indexOf('yoco');
  let cookieDomain = undefined;
  if (firstYocoPos > 0) {
    cookieDomain = domain.substring(firstYocoPos);
  } else {
    cookieDomain = domain;
  }

  console.debug(`This is the cookie domain -> ${cookieDomain}`)

  return cookieDomain;
};

const cookieDomain = getCookieDomain();

/** simple function for creating a given cookie*/
browser.createCookie = (name, value, days) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toGMTString();
  }

  document.cookie = `${name}=${value}${expires}; path=/; domain=${cookieDomain}`;
};

/** simple function to read the value of a cookie*/
browser.readCookie = (name) => {
  const nameEQ = `${name}=`;
  let ca = document.cookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/** fetch a query parameter by name**/
browser.getParameterByName = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default browser;