/* global document */
/* eslint-disable eol-last */
/* eslint no-undef: "off" */
import browser from './libs/browser';
import config from './config';
import signup from './libs/signup';


console.debug("Yoco site enhancer is available...");

const domain = document.domain;

const defaultCookieExpiry = 90;
const admarulaCookieExpiry = 14;

/** add segment to the page */
const setupSegment = () => {
  //Rest of segment required code
  // Create a queue, but don't obliterate an existing one!
  let analytics = window.analytics = window.analytics || [];

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on'
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    let key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.segment.com/analytics.js/v1/'
      + key + '/analytics.min.js';

    // Insert our script next to the first script element.
    let first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(config.segmentKey);

  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page();
};

/** We want to store some utm data and the google client id in our browser*/
const storeTrackingData = () => {
  // store utm data in the cookies for later reuse
  let utmSource = browser.getParameterByName('utm_source');
  let utmMedium = browser.getParameterByName('utm_medium');
  let utmCampaign = browser.getParameterByName('utm_campaign');

  // store google click id
  let googleClickId = browser.getParameterByName('gclid');

  // store the admarula tmtData tag
  let tmtData = browser.getParameterByName('tmtData');

  if (!browser.readCookie('yc_utm_source')) {
    browser.createCookie('yc_utm_source', utmSource, defaultCookieExpiry);
    browser.createCookie('yc_utm_medium', utmMedium, defaultCookieExpiry);
    browser.createCookie('yc_utm_campaign', utmCampaign, defaultCookieExpiry);
  }

  if (googleClickId) {
    browser.createCookie('yc_google_click_id', googleClickId, defaultCookieExpiry);
  }

  if (tmtData) {
    // Create a 30 day attribution window for the tmtData cookie, if one is provided for the admarula tracking
    browser.createCookie('yc_tmt_data', tmtData, admarulaCookieExpiry);
  }

  analytics.ready(function () {
    // GA library methods here
    ga(function (tracker) {
      const clientId = tracker.get('clientId');
      const existingClientId = browser.readCookie('gaClientId')
      if (clientId && !existingClientId) {
        browser.createCookie('yc_ga_client_id', clientId, defaultCookieExpiry);
        browser.createCookie('gaClientId', clientId, defaultCookieExpiry);
        console.debug(`Got a google clientId = ${clientId}`);
      } else if (clientId != existingClientId) {
        console.debug(`ClientId mismatch. ${clientId} != ${existingClientId}`);
      }
    });
  })
};

const setupSleekNote = () => {
  (function () {
    let sleeknoteScriptTag = document.createElement('script');
    sleeknoteScriptTag.type = 'text/javascript';
    sleeknoteScriptTag.charset = 'utf-8';
    sleeknoteScriptTag.src = ('//sleeknotecustomerscripts.sleeknote.com/6897.js');
    sleeknoteScriptTag.defer = true;

    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(sleeknoteScriptTag, firstScriptTag);
  })();
};

const setupGTM = () => {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-N65QCC2');
}

const setupDrift = () => {
  let t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
    t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
      t.factory = function (e) {
        return function () {
          var n = Array.prototype.slice.call(arguments);
          return n.unshift(e), t.push(n), t;
        };
      }, t.methods.forEach(function (e) {
      t[e] = t.factory(e);
    }), t.load = function (t) {
      let e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
      o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
      let i = document.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(o, i);
    };
  }
  drift.SNIPPET_VERSION = '0.3.1';
  drift.load('3b53zfv4awbw');
};

const setupAutoPilot = () => {
  let o = {};
  let b = "https://api.autopilothq.com/anywhere/",
    t = "d05c0950fc274b3aa2319be8249050cd102efbc4db784933a4cb8cb9d1a21ee4", a = window.AutopilotAnywhere = {
      _runQueue: [], run: function () {
        this._runQueue.push(arguments);
      }
    }, c = encodeURIComponent, s = "SCRIPT", d = document, l = d.getElementsByTagName(s)[0],
    p = "t=" + c(d.title || "") + "&u=" + c(d.location.href || "") + "&r=" + c(d.referrer || ""),
    j = "text/javascript", z, y;
  if (!window.Autopilot) window.Autopilot = a;
  if (o.app) p = "devmode=true&" + p;
  z = function (src, asy) {
    let e = d.createElement(s);
    e.src = src;
    e.type = j;
    e.async = asy;
    l.parentNode.insertBefore(e, l);
  };
  y = function () {
    z(b + t + '?' + p, true);
  };
  if (window.attachEvent) {
    window.attachEvent("onload", y);
  } else {
    window.addEventListener("load", y, false);
  }
};

/** setup our handler for submitting the signup form on instapage*/
const setupInstagePageSignupFormSubmit = () => {
  window.instapageFormSubmitSuccess = (form) => {
    if (!ijQuery)
      return;

    const $ = ijQuery;

    //check if name exists, which means, it's not signup form (instapages hack)
    const name = $(form).find(`input[name="${window.base64.base64_encode('First Name')}"]`).val();

    if(name){
      console.log("Call Back Request");
      return;
    }
    
    // Fetch email address and mobile number
    const email = $(form).find(`input[name="${window.base64.base64_encode('Email')}"]`).val();
    const mobileNumber = $(form).find(`input[name="${window.base64.base64_encode('Phone Number')}"]`).val();

    if (!mobileNumber || !email) {
      // if these fields do not exist this is not a valid attempt to signup, more likely just a web to lead capture
      console.error("Coud not find email or mobile number")
      return;
    }

    // Fetch the promoCode from the input field
    let promoCode =
      $(form).find('input[name="' + window.base64.base64_encode('PromoCode') + '"]').val();

    if (promoCode == "[promoCode]") {
      promoCode = "";
    }

    promoCode = promoCode || browser.getParameterByName('promoCode');

    const utmSource = browser.readCookie('yc_utm_source') || browser.getParameterByName('utm_source');
    const utmMedium = browser.readCookie('yc_utm_medium') || browser.getParameterByName('utm_medium');
    const utmCampaign = browser.readCookie('yc_utm_campaign') || browser.getParameterByName('utm_campaign');

    const googleClickId = browser.getParameterByName('gclid') || browser.readCookie('yc_google_click_id');
    const tmtData = browser.getParameterByName('tmtData') || browser.readCookie('yc_tmt_data');

    const utmData = {
      utmSource,
      utmCampaign,
      utmMedium,
      tmtData,
      googleClickId
    };

    try {
      utmData.gaClientId = browser.readCookie('gaClientId') || window.ga.getAll()[0].get("clientId");
    } catch (e) {
      console.debug("ga - not found");
    }

    console.debug('utmData>', utmData);

    // Now we have all the data we need to actually start a sign up on core, so that is what we are going to do...
    signup.startSignup({
      email: email,
      user: {
        mobileNumber: mobileNumber
      },
      marketing: {
        promoCode: (promoCode || window.promoCode)
      },
      utm: utmData
    })
      .then(response => {

        window.analytics && window.analytics.track("[signup] sign-up started");
        const redirectUrl = response.data.signUp.uuid;
        window.location = 'https://signup.yoco.co.za/continue/' + redirectUrl;
      })
      .catch(error => {
        console.debug('error received from signup', error);
        $('#element-192 p font').text(error.message);
        $('#element-192').show();
        $('body').click(function () {
          $('#element-192').hide();
        });
      });
  };
};

// Actually run each of the steps we need to run
setupSegment();
storeTrackingData();
setupSleekNote();
setupGTM();

if (['promo.yoco.co.za', 'get.yoco.co.za', 'go.yoco.co.za', 'www.yoco.co.za'].includes(domain)) {
  // we only want to run thse on our promo and main domain for now

  setupAutoPilot();
  setupInstagePageSignupFormSubmit();
}

if (['www.yoco.co.za'].includes(domain)) {
  setupDrift();
}

