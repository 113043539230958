import config from '../config';

const signup = {};

/** start a signup with our core backend server*/
signup.startSignup = (data) => {
  const url = `${config.apiURL}/api/client/v1/signUp/reset`;

  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      /** A quirk of cors is that if you use text plain it does not need to do a double query*/
      'Content-Type': 'text/plain'
    }
  };

  return _fetch(url, options).then((response) => {
    // track our data to analytics if we have successfully started a signup...
    window.analytics && window.analytics.identify(response.data.signUp.userUUID);
    return response;
  });
};

/** this is an intelligent version of fetch that is able to parse responses from yoco servers */
const _fetch = (url, options) => {
  return fetch(url, options)
    .then(response => {
      return response.json();
    }).then(json => {
      if (json.status == 200) {
        return json;
      } else {
        throw json;
      }
    }).catch(error => {
      if (!error.message) {
        error.message = "Request could not be processed, please try again later.";
      }
      throw error;
    });
};

export default signup;